import * as THREE from 'three'

import { landingSphere } from './threeComponents/landingObject';

export default class ThreeInit {
    constructor() {
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1.5));
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.setClearColor('black', 1);
      
      this.camera = new THREE.PerspectiveCamera(
        45,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      this.camera.position.set(0, 0, 4);    
      
      this.scene = new THREE.Scene();
      this.scene.background = new THREE.Color('#FFF5FF');

      this.sizes = {
        width: window.innerWidth,
        height: window.innerHeight
      }
      this.scrollY = window.scrollY;

      this.projectBlobs = [];
      
      this.init();
      this.animate();

      this.resize = this.resize.bind(this);
      this.onScroll = this.onScroll.bind(this);

      window.addEventListener('resize', this.resize, false);
      window.addEventListener('scroll', this.onScroll, false);
    }
    
    init() {
      this.addCanvas();

      let targetColor = "#00FF00";
      let rChannel = this.normalizedHexToRgb(targetColor).r;
      let gChannel = this.normalizedHexToRgb(targetColor).g;
      let bChannel = this.normalizedHexToRgb(targetColor).b;

      let brightness = [
        1 - (((1-rChannel)/2)*Math.cos(2*Math.PI)),
        1 - (((1-gChannel)/2)*Math.cos(2*Math.PI)),
        1 - (((1-bChannel)/2)*Math.cos(2*Math.PI))
      ];

      let contrast = [
        ((1-rChannel)/2)*Math.cos(2*Math.PI),
        ((1-gChannel)/2)*Math.cos(2*Math.PI),
        ((1-bChannel)/2)*Math.cos(2*Math.PI)
      ];

      this.landingObject = new landingSphere(this.scene,
        brightness, // brightness (R, G, B channels)
        contrast, // contrast (R, G, B channels)
        [1, 1, 1], // frequency (R, G, B channels)
        [0, 0, 0], // phase (R, G, B channels)
        [1.5], // intensity
        true
        // see https://www.youtube.com/watch?v=hdzen9_86D4&t=2015s for explainations
        // see https://www.desmos.com/calculator/rz7abjujdj for more details
        );
    }

    hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
    }
  
    normalizedHexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: (parseInt(result[1], 16) - 0) / (255 - 0),
          g: (parseInt(result[2], 16) - 0) / (255 - 0),
          b: (parseInt(result[3], 16) - 0) / (255 - 0)
        } : null;
    }

    addCanvas() {
      const canvas = this.renderer.domElement;
      canvas.classList.add('webgl');
      document.body.appendChild(canvas);
    } 
    
    resize() {
      this.sizes = {
          width: window.innerWidth,
          height: window.innerHeight
      };
      let width = window.innerWidth;
      let height = window.innerHeight;
  
      this.camera.aspect = width / height;
      this.renderer.setSize(width, height);
  
      this.camera.updateProjectionMatrix();
    }

    onScroll() {
      this.scrollY = window.scrollY;

      if(!this.fixed
          || window.matchMedia("(max-width: 480px) and (orientation: portrait)").matches
          || window.matchMedia("(min-width: 768px) and (max-width: 991px) and (orientation: portrait)").matches
        ) {
        this.camera.position.y = - this.scrollY / this.sizes.height * 3;
      }
    }
    
    animate() {
      requestAnimationFrame(this.animate.bind(this));
      this.render();
    }
    
    render() {
      this.renderer.render(this.scene, this.camera);
    }

    update() {
      if (this.renderer === undefined) return
      requestAnimationFrame(this.update.bind(this))
      this.renderer.render(this.scene, this.camera)
    }
}