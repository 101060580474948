import './style/style.css';

import React from 'react';
import Home from './pages/home';
import ThreeInit from './components/three';
import Cursor from './components/cursor';

import $ from 'jquery';

const gsap = window.gsap;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayingHome: true,
      project: '',
      threeJsScene: null,
      colors: null
    }
  }

  componentDidMount() {
    this.setState({threeJsScene: new ThreeInit()});
  }

  componentDidUpdate() {
    setTimeout(function() { 
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    }, 10)
  }

  setDisplayingHome = (boolean) => {
    this.setState({
      displayingHome: boolean
    });
  }

  setCursor = (colors, hoverElements, focusElements) => {
    this.setState({
      colors: colors,
      hoverElements: hoverElements,
      focusElements: focusElements
    });
  }

  changeColor = (sphereColor, bgColor) => {
    // Sphere
    let rChannel = this.normalizedHexToRgb(sphereColor).r;
    let gChannel = this.normalizedHexToRgb(sphereColor).g;
    let bChannel = this.normalizedHexToRgb(sphereColor).b;

    this.colorTo(this.state.threeJsScene.landingObject.mesh.material.uniforms.uBrightnessR, 1 - (((1-rChannel)/2)*Math.cos(2*Math.PI)));
    this.colorTo(this.state.threeJsScene.landingObject.mesh.material.uniforms.uBrightnessG, 1 - (((1-gChannel)/2)*Math.cos(2*Math.PI)));
    this.colorTo(this.state.threeJsScene.landingObject.mesh.material.uniforms.uBrightnessB, 1 - (((1-bChannel)/2)*Math.cos(2*Math.PI)));
    
    this.colorTo(this.state.threeJsScene.landingObject.mesh.material.uniforms.uContrastR, ((1-rChannel)/2)*Math.cos(2*Math.PI));
    this.colorTo(this.state.threeJsScene.landingObject.mesh.material.uniforms.uContrastG, ((1-gChannel)/2)*Math.cos(2*Math.PI));
    this.colorTo(this.state.threeJsScene.landingObject.mesh.material.uniforms.uContrastB, ((1-bChannel)/2)*Math.cos(2*Math.PI));

    $('.cursors').removeClass('focus, hover');

    // Intensity
    this.colorTo(this.state.threeJsScene.landingObject.mesh.material.uniforms.uIntensity, 1.5);

    // Phase
    this.colorTo(this.state.threeJsScene.landingObject.mesh.material.uniforms.uPhaseR, 0);
    this.colorTo(this.state.threeJsScene.landingObject.mesh.material.uniforms.uPhaseG, 0);
    this.colorTo(this.state.threeJsScene.landingObject.mesh.material.uniforms.uPhaseB, 0);

    // Bg
    let bgChannels = {
        r: this.normalizedHexToRgb(bgColor).r,
        g: this.normalizedHexToRgb(bgColor).g,
        b: this.normalizedHexToRgb(bgColor).b
    }

    setTimeout(() => {
      this.bgColorTo(this.state.threeJsScene.scene.background, bgChannels);
    }, 2000);
  }

  setHomeParams = () => {
    this.state.threeJsScene.fixed = false;

    // Intensity
    this.colorTo(this.state.threeJsScene.landingObject.mesh.material.uniforms.uIntensity, 0.5);

    // Phase
    this.colorTo(this.state.threeJsScene.landingObject.mesh.material.uniforms.uPhaseR, 0.0);
    this.colorTo(this.state.threeJsScene.landingObject.mesh.material.uniforms.uPhaseG, 0.3);
    this.colorTo(this.state.threeJsScene.landingObject.mesh.material.uniforms.uPhaseB, 0.1);
  }

  normalizedHexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: (parseInt(result[1], 16) - 0) / (255 - 0),
        g: (parseInt(result[2], 16) - 0) / (255 - 0),
        b: (parseInt(result[3], 16) - 0) / (255 - 0)
      } : null;
  }

  colorTo(property, value) {
      var initial = property;
      gsap.to(property, {
          duration: 1,
          value: value,

          onUpdate: function () {
              property = initial;
          }
      });
  }

  bgColorTo(property, value) {
      var initial = property;
      gsap.to(property, {
          duration: 1,
          r: value.r,
          g: value.g,
          b: value.b,

          onUpdate: function () {
              property = initial;
          }
      });
  }

  //Render
  render() {
    return(
      <div className="App">
        <div className="container">
          <Cursor colors={this.state.colors} />

          <Home
            threeJsScene={this.state.threeJsScene}
            changeColor={this.changeColor}
            setCursor={this.setCursor}
            setDisplayingHome={this.setDisplayingHome}
            setHomeParams={this.setHomeParams}
          />
        </div>
      </div>
    );
  }
}

export default App;