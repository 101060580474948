import { Component } from "react";

import $ from 'jquery';

class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    componentDidMount() {
        document.querySelector('h1').addEventListener('mouseover', function() {
            $('.cursors').addClass('hover');
        })

        document.querySelector('h1').addEventListener('mouseout', function() {
            $('.cursors').removeClass('hover');
        })

        if (DeviceOrientationEvent && typeof(DeviceOrientationEvent.requestPermission) === "function") {
            document.getElementById("buttons").innerHTML = '<button id="aksOrientation">Enable sphere interactivity</button>';
        }
    }

    render() {
        return(
            <>
                <section id="landing">
                    <h1>soon...</h1>
                    <div id="buttons"></div>
                </section>
            </>
        )
    }
}

export default Landing;