import * as THREE from 'three'

const TweenLite = window.TweenLite;

// const gui = new dat.GUI();

const settings = {
  speed: 0.2,
  density: 1.5,
  strength: 0.2,
  frequency: 1,
  amplitude: 1,
  intensity: 1.5,
  brightnessRGB: [0.5, 0.5, 0.5],
  contrastRGB: [0.5, 0.5, 0.5],
  frequencyRGB: [1, 1, 1],
  phaseRGB: [0.0, 0.0, 0.0]
};
const mouse = {
    x: 0,
    y: 0
}
// const folder1 = gui.addFolder('Noise');
// const folder2 = gui.addFolder('Rotation');
// const folder3 = gui.addFolder('Color');
// folder1.add(settings, 'speed', 0.1, 1, 0.01);
// folder1.add(settings, 'density', 0, 10, 0.01);
// folder1.add(settings, 'strength', 0, 2, 0.01);
// folder2.add(settings, 'frequency', 0, 10, 0.1);
// folder2.add(settings, 'amplitude', 0, 10, 0.1);
// folder3.add(settings, 'intensity', 0, 10, 0.1);

const noise = `
  vec3 mod289(vec3 x)
  {
    return x - floor(x * (1.0 / 289.0)) * 289.0;
  }

  vec4 mod289(vec4 x)
  {
    return x - floor(x * (1.0 / 289.0)) * 289.0;
  }

  vec4 permute(vec4 x)
  {
    return mod289(((x*34.0)+1.0)*x);
  }

  vec4 taylorInvSqrt(vec4 r)
  {
    return 1.79284291400159 - 0.85373472095314 * r;
  }

  vec3 fade(vec3 t) {
    return t*t*t*(t*(t*6.0-15.0)+10.0);
  }

  // Classic Perlin noise, periodic variant
  float pnoise(vec3 P, vec3 rep)
  {
    vec3 Pi0 = mod(floor(P), rep); // Integer part, modulo period
    vec3 Pi1 = mod(Pi0 + vec3(1.0), rep); // Integer part + 1, mod period
    Pi0 = mod289(Pi0);
    Pi1 = mod289(Pi1);
    vec3 Pf0 = fract(P); // Fractional part for interpolation
    vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
    vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
    vec4 iy = vec4(Pi0.yy, Pi1.yy);
    vec4 iz0 = Pi0.zzzz;
    vec4 iz1 = Pi1.zzzz;

    vec4 ixy = permute(permute(ix) + iy);
    vec4 ixy0 = permute(ixy + iz0);
    vec4 ixy1 = permute(ixy + iz1);

    vec4 gx0 = ixy0 * (1.0 / 7.0);
    vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
    gx0 = fract(gx0);
    vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
    vec4 sz0 = step(gz0, vec4(0.0));
    gx0 -= sz0 * (step(0.0, gx0) - 0.5);
    gy0 -= sz0 * (step(0.0, gy0) - 0.5);

    vec4 gx1 = ixy1 * (1.0 / 7.0);
    vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
    gx1 = fract(gx1);
    vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
    vec4 sz1 = step(gz1, vec4(0.0));
    gx1 -= sz1 * (step(0.0, gx1) - 0.5);
    gy1 -= sz1 * (step(0.0, gy1) - 0.5);

    vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
    vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
    vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
    vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
    vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
    vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
    vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
    vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

    vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
    g000 *= norm0.x;
    g010 *= norm0.y;
    g100 *= norm0.z;
    g110 *= norm0.w;
    vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
    g001 *= norm1.x;
    g011 *= norm1.y;
    g101 *= norm1.z;
    g111 *= norm1.w;

    float n000 = dot(g000, Pf0);
    float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
    float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
    float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
    float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
    float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
    float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
    float n111 = dot(g111, Pf1);

    vec3 fade_xyz = fade(Pf0);
    vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
    vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
    float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);
    return 2.2 * n_xyz;
  }
`;

const rotation = `
  mat3 rotation3dY(float angle) {
    float s = sin(angle);
    float c = cos(angle);

    return mat3(
      c, 0.0, -s,
      0.0, 1.0, 0.0,
      s, 0.0, c
    );
  }
  
  vec3 rotateY(vec3 v, float angle) {
    return rotation3dY(angle) * v;
  }  
`;

const vertexShader = `  
  varying vec2 vUv;
  varying float vDistort;
  
  uniform float uTime;
  uniform float uSpeed;
  uniform float uNoiseDensity;
  uniform float uNoiseStrength;
  uniform float uFrequency;
  uniform float uAmplitude;
  
  ${noise}
  
  ${rotation}
  
  void main() {
    vUv = uv;
    
    float t = uTime * uSpeed;
    float distortion = pnoise((normal + t) * uNoiseDensity, vec3(10.0)) * uNoiseStrength;

    vec3 pos = position + (normal * distortion);
    float angle = sin(uv.y * uFrequency + t) * uAmplitude;
    pos = rotateY(pos, angle);    
    
    vDistort = distortion;

    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.);
  }  
`;

const fragmentShader = `
  varying vec2 vUv;
  varying float vDistort;
  
  uniform float uTime;
  uniform float uIntensity;

  uniform float uFrequencyR;
  uniform float uFrequencyG;
  uniform float uFrequencyB;

  uniform float uContrastR;
  uniform float uContrastG;
  uniform float uContrastB;

  uniform float uPhaseR;
  uniform float uPhaseG;
  uniform float uPhaseB;

  uniform float uBrightnessR;
  uniform float uBrightnessG;
  uniform float uBrightnessB;
  
  vec3 cosPalette(float t, vec3 a, vec3 b, vec3 c, vec3 d) {
    return a + b * cos(6.28318 * (c * t + d));
  }     
  
  void main() {
    float distort = vDistort * uIntensity;
    
    vec3 brightness = vec3(uBrightnessR, uBrightnessG, uBrightnessB);
    vec3 contrast = vec3(uContrastR, uContrastG, uContrastB);
    vec3 oscilation = vec3(uFrequencyR, uFrequencyG, uFrequencyB);
    vec3 phase = vec3(uPhaseR, uPhaseG, uPhaseB);
    
    vec3 color = cosPalette(distort, brightness, contrast, oscilation, phase);
    
    gl_FragColor = vec4(color, 1.0);
  }  
`;

export class landingSphere {
  constructor(scene, brightnessRGB, contrastRGB, fixed) {
    this.scene = scene;

    this.brightnessRGB = brightnessRGB;
    this.contrastRGB = contrastRGB;

    this.phonePortrait = false;
    this.phoneLandscape = false;
    this.tabletPortrait = false;
    this.tabletLandscape = false;
    this.desktop = true;

    fixed ? this.fixed = fixed : this.fixed = false;

    this.clock = new THREE.Clock();
    this.init();
    this.animate();
  }
  
  init() {
    this.addElements();
    this.addEvents();
    this.onScroll();
  }
  
  addElements() {
    const geometry = new THREE.IcosahedronBufferGeometry(1, 64);
    const material = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: {
        uTime: { value: 0 },
        uSpeed: { value: settings.speed },
        uNoiseDensity: { value: settings.density },
        uNoiseStrength: { value: settings.strength },
        uFrequency: { value: settings.frequency },
        uAmplitude: { value: settings.amplitude },
        uIntensity: { value: settings.intensity },

        uFrequencyR: { value: settings.frequencyRGB[0] },
        uFrequencyG: { value: settings.frequencyRGB[1] },
        uFrequencyB: { value: settings.frequencyRGB[2] },

        uContrastR: { value: settings.contrastRGB[0] },
        uContrastG: { value: settings.contrastRGB[1] },
        uContrastB: { value: settings.contrastRGB[2] },

        uPhaseR: { value: settings.phaseRGB[0] },
        uPhaseG: { value: settings.phaseRGB[1] },
        uPhaseB: { value: settings.phaseRGB[2] },

        uBrightnessR: { value: settings.brightnessRGB[0] },
        uBrightnessG: { value: settings.brightnessRGB[1] },
        uBrightnessB: { value: settings.brightnessRGB[2] }
      },
      // wireframe: true,
    });

    if(this.contrastRGB && this.brightnessRGB) {
      material.uniforms.uContrastR.value = this.contrastRGB[0];
      material.uniforms.uContrastG.value = this.contrastRGB[1];
      material.uniforms.uContrastB.value = this.contrastRGB[2];

      material.uniforms.uBrightnessR.value = this.brightnessRGB[0];
      material.uniforms.uBrightnessG.value = this.brightnessRGB[1];
      material.uniforms.uBrightnessB.value = this.brightnessRGB[2];
    }

    this.mesh = new THREE.Mesh(geometry, material);
    this.mesh.position.set(0, .3, 0);
    this.mesh.scale.set(.5, .5, .5);
    this.scene.add(this.mesh);
    
    // Responsive
    this.updateVariables();
  }
  
  addEvents() {
    window.addEventListener('mousemove', this.onMouseMove.bind(this));
    window.addEventListener('resize', this.updateVariables.bind(this));
    this.updateVariables();
  }
  
  addEventPermission() {
    document.querySelector('#buttons button').addEventListener('click', this.requestPermission.bind(this));
  }

  onMouseMove(e) {
    if(!this.phonePortrait && !this.phoneLandscape && !this.tabletPortrait && !this.tabletLandscape) {
      mouse.x = (e.clientX / window.innerWidth).toFixed(2) * 6 + 1;
      mouse.y = (e.clientY / window.innerHeight).toFixed(2) * 0.3 + 0.2;
  
      settings.frequency = mouse.x;
      settings.strength = mouse.y;
    }
  }

  onScroll() {
    if(!this.fixed) {
      let currentScroll = window.pageYOffset;
    }
  }
  
  animate() {
    requestAnimationFrame(this.animate.bind(this));
    this.render();
  }
  
  render() {
    this.mesh.material.uniforms.uTime.value = this.clock.getElapsedTime();
    this.mesh.material.uniforms.uSpeed.value = settings.speed;
    this.mesh.material.uniforms.uNoiseDensity.value = settings.density;
    this.mesh.material.uniforms.uNoiseStrength.value = settings.strength;
    this.mesh.material.uniforms.uFrequency.value = settings.frequency;
    this.mesh.material.uniforms.uAmplitude.value = settings.amplitude;
  }

  colorTo(property, value) {
    var initial = property;
    TweenLite.to(property, 1, {
        value,

        onUpdate: function () {
            property = initial;
        }
    });
  }

  updateVariables() {
    // Update variables
    if(window.matchMedia("(max-width: 480px) and (orientation: portrait)").matches && !this.phonePortrait) { // Phone portrait
      this.addEventPermission();
      this.mesh.position.set(0, .3, 0);
      this.mesh.scale.set(.4, .4, .4);

      this.phonePortrait = true;
      this.phoneLandscape = false;
      this.tabletPortrait = false;
      this.tabletLandscape = false;
      this.desktop = false;
    } else if(window.matchMedia("(min-width: 480px) and (max-width: 991px) and (orientation: landscape)").matches && !this.phoneLandscape) { // Phone landscape
      this.addEventPermission();
      this.mesh.position.set(0, .5, 0);
      this.mesh.scale.set(.5, .5, .5);

      this.phonePortrait = false;
      this.phoneLandscape = true;
      this.tabletPortrait = false;
      this.tabletLandscape = false;
      this.desktop = false;
    } else if(window.matchMedia("(min-width: 768px) and (max-width: 991px) and (orientation: portrait)").matches && !this.tabletPortrait) { // Tablet portrait
      this.addEventPermission();
      this.mesh.position.set(0, .3, 0);
      this.mesh.scale.set(.5, .5, .5);

      this.phonePortrait = false;
      this.phoneLandscape = false;
      this.tabletPortrait = true;
      this.tabletLandscape = false;
      this.desktop = false;
    } else if(window.matchMedia("(min-width: 992px) and (max-width: 1199px) and (orientation: landscape)").matches && !this.tabletLandscape) { // Tablet landscape
      this.addEventPermission();
      this.mesh.position.set(0, .3, 0);
      this.mesh.scale.set(.5, .5, .5);

      this.phonePortrait = false;
      this.phoneLandscape = false;
      this.tabletPortrait = false;
      this.tabletLandscape = true;
      this.desktop = false;
    } else if(window.matchMedia("(min-width: 1200px)").matches && !this.desktop) { // PC
      this.mesh.position.set(0, .3, 0);
      this.mesh.scale.set(.5, .5, .5);

      this.phonePortrait = false;
      this.phoneLandscape = false;
      this.tabletPortrait = false;
      this.tabletLandscape = false;
      this.desktop = true;
    }
  }

  requestPermission() {
    document.querySelector('#buttons button').remove();
    let scope = this;
    if(this.phonePortrait || this.phoneLandscape || this.tabletPortrait || this.tabletLandscape) {
      window.removeEventListener('deviceorientation', this.onDeviceOrientation);
      if (DeviceOrientationEvent && typeof(DeviceOrientationEvent.requestPermission) === "function") {
        DeviceOrientationEvent.requestPermission()
            .then( response => {
              if ( response == "granted" ) {
                  window.addEventListener('deviceorientation', this.onDeviceOrientation.bind(scope))
              }
            })
      } else {
        window.addEventListener('deviceorientation', this.onDeviceOrientation.bind(scope))
      }
    } else {
      window.removeEventListener('deviceorientation', this.onDeviceOrientation);
    }
  }

  onDeviceOrientation(e) {
    let beta = Math.abs(e.beta); // 180
    let gamma = Math.abs(e.gamma); // 90

    beta = ((7 - 1) / (180 - 0)) * (beta - 0) + 1;
    gamma = ((0.5 - 0.2) / (90 - 0)) * (gamma - 0) + 0.2;

    settings.frequency = beta; // 1 - 7
    settings.strength = gamma; // 0.2 - 0.5
  }
}