import { Component } from "react";

import Landing from '../components/landing.jsx'

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    componentDidMount() {
        // Go to top and trigger scroll event
        window.scroll(0, 1);
        window.scroll(0, 0);
        
        // Set Three colors
        if(this.props.threeJsScene) {
            this.props.changeColor('#BC00D8', '#FFF5FF');
            this.props.setHomeParams();
        }
        this.props.setCursor(
            '#BC00D8',
            document.querySelectorAll('p, span, a, h1, h2, h3, .memberSkill, #formNext, #formPrevious, input, textarea'),
            document.querySelectorAll('.hover')
        );

        document.getElementById('homeContainer').style.opacity = 1;
    }

    componentDidUpdate(prevProps) {
        if(this.props.threeJsScene !== prevProps.threeJsScene) {
            this.props.changeColor('#BC00D8', '#FFF5FF');
            this.props.setHomeParams();
            this.props.setCursor(
                '#BC00D8',
                document.querySelectorAll('p, span, a, h1, h2, h3, .memberSkill, #formNext, #formPrevious, input, textarea'),
                document.querySelectorAll('.hover')
            );
        }
    }

    componentWillUnmount() {

    }

    render() {
        return(
            <>
            <div id="homeContainer" className="container">

                <Landing/>
                
            </div>
            </>
        )
    }
}

export default Home;