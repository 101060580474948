import { Component } from "react";
import $ from 'jquery';

class Cursor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: '',
            hoverElements: document.querySelectorAll('p, span, a, h1, h2, h3, .memberSkill, #formNext, #formPrevious, input, textarea'),
            focusElements: document.querySelectorAll('.hover'),
            aimX: 0,
            aimY: 0
        }

        this.onMouseMove = this.onMouseMove.bind(this);
        this.setElements = this.setElements.bind(this);
        // this.setListener()
    }

    componentDidMount() {
        let scope = this;

        const cursorTab = document.querySelector('div.cursors');
        const cursorElements = cursorTab.querySelectorAll('div');

        cursorElements.forEach((el, i) => {
            let currentX = 0;
            let currentY = 0;

            let speed = .3 + i * .015;
            let parallaxSpeed = 1;

            const animate = function() {
                currentX += (scope.state.aimX - currentX) * speed;
                currentY += (scope.state.aimY - currentY) * speed;

                el.style.left = currentX + 'px';
                el.style.top = currentY + 'px';

                if(document.querySelector('.blobHover')) {
                    document.querySelector('.blobHover').style.left = currentX + 'px';
                    document.querySelector('.blobHover').style.top = currentY + 'px';

                    document.querySelector('.blobHover .blobHoverText').style.transform = `translateX(${(scope.state.aimX - currentX) * parallaxSpeed}px) translateY(${(scope.state.aimY - currentY) * parallaxSpeed}px)`;
                }

                requestAnimationFrame(animate);
            }

            animate();
        });

        this.setState({
            hoverElements: document.querySelectorAll('p, span, a, h1, h2, h3, .memberSkill, #formNext, #formPrevious, input, textarea')
        });
        
        document.addEventListener('mousemove', event => { this.onMouseMove(event) })
    }

    componentDidUpdate(prevProps) {
        if(this.props.colors !== prevProps.colors) {
            if(this.props.hoverElements != null && this.props.focusElements != null) {
                this.setState({
                    color: this.props.colors.title,
                    hoverColor: this.props.colors.title+'07',
                    hoverElements: this.props.hoverElements,
                    focusElements: this.props.focusElements
                });
            } else {
                this.setState({
                    color: this.props.colors.title,
                    hoverColor: this.props.colors.title+'07',
                    hoverElements: document.querySelectorAll('p, span, a, h1, h2, h3, .memberSkill, #formNext, #formPrevious, input, textarea'),
                    focusElements: document.querySelectorAll('.hover')
                });
            }
        }
    }

    setElements(hoverElements, focusElements) {
        if(!this.eq(this.state.hoverElements, hoverElements) || !this.eq(this.state.focusElements, focusElements)) {
            this.setState({
                hoverElements: hoverElements,
                focusElements: focusElements
            });

            $(this.state.hoverElements).on('mouseenter', function() { 
                $('.cursors').addClass('hover');
            }).on('mouseleave', function() {
                $('.cursors').removeClass('hover');
            });

            $(this.state.focusElements).on('mouseenter', function() { 
                $('.cursors').addClass('focus');
            }).on('mouseleave', function() {
                $('.cursors').removeClass('focus');
            });
            // this.setListener();
        }
    }

    eq(A, B) {
        if (A.length !== B.length) return false;
        for (let i = 0; i < A.length; i++) {
          if (A[i] !== B[i]) return false;
        }
        return true;
    }

    setListener() {
        this.state.hoverElements.forEach((el, i) => {
            el.addEventListener('mouseover', function() {
                $('.cursors').addClass('hover');
            })

            el.addEventListener('mouseout', function() {
                $('.cursors').removeClass('hover');
            })
        });

        this.state.focusElements.forEach((el, i) => {
            el.addEventListener('mouseover', function() {
                $('.cursors').addClass('focus');
            })

            el.addEventListener('mouseout', function() {
                $('.cursors').removeClass('focus');
            })
        });
    }

    onMouseMove(event) {
        this.setState({
            aimX: event.clientX,
            aimY: event.clientY
        });
        let hoverElements = document.querySelectorAll('p, span, a, h1, h2, h3, .memberSkill, #formNext, #formPrevious, input, textarea, #logo');
        let focusElements = document.querySelectorAll('.hover, .backToHomeContainer, img');
        this.setElements(hoverElements, focusElements);
    }

    render() {
        return (
            <>
                <div className="cursors">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </>
        )
    }
}

export default Cursor;